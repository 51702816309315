import { useState } from "react";

const TestingPage = () => {

    const [count, setCount] = useState(0);
    
    const handleClick = () => {
        setCount(count + 1);
    };

    return(
        <div className="container">
            <h1>TestingPage</h1>
            <button onClick={handleClick}>Button</button>
            <p>Click : {count}</p>
        </div>
    );
}


export default TestingPage;