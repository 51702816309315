import { useState, useEffect } from "react";

const useFetch = (url) =>{
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() =>{
        fetch(url)
        .then(res => {
            if(!res.ok){
                throw Error('Could not fetch')
            }
            return res.json();
        })
        .then(data => {
            // Success
            setData(data);
            setIsPending(false);
            setError(null);
        })
        .catch(err => {
            // Fail
            setIsPending(false);
            setError(err.message);
        })
    }, [url]);

    return {data, isPending, error, url}
}

export default useFetch;